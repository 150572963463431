import React from 'react';
import { Link } from "react-router-dom";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';


const Footer = () => {
  return (
    <div>
      <div className='fixed bottom-20 right-10 md:fixed md:bottom-10 md:right-5'>
        <button className='bg-[#7F57F1] text-white rounded-full px-3 py-3'>
          <QuestionAnswerIcon />
        </button>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-10 py-20 md:py-36'>
        <div className='flex flex-col justify-between h-30 md:h-40'>
          <h1 className="text-[30px] text-[#7F57F1]  font-[1000]">FABOOF</h1>
        </div>
        <div className='h-30 md:h-40'>
          <h1 className="font-poppinsBold text-[16px]">Company</h1>
          <div className='text-[#646464] font-poppinsLight text-[14px] pt-4'>Terms and conditions</div>
          <div className='text-[#646464] font-poppinsLight text-[14px] pt-4'>Privacy Policy</div>
        </div>
        <div className='h-30 md:h-40'>
          <h1 className="font-poppinsBold text-[16px]">Support</h1>
          <Link to='/contact'><button className='text-[#646464] font-poppinsLight text-[14px] pt-4 cursor-pointer'>Contact Us</button></Link>
        </div>
        <div className='h-30 md:h-40 hidden md:block'>
          <h1 className="font-poppinsBold text-[16px]">Newsletter</h1>
          <div className='text-[#646464] font-poppinsLight text-[14px] pt-10'>Be the first to get the latest news about trends and promotions and much more!</div>
          <div>
            <form className='pt-4'>
              <input type='email' placeholder='Enter your email' className='border border-gray-300 rounded-md p-2 w-40' />
              <button type='submit' className='bg-[#7F57F1] text-white rounded-md px-4 py-2 ml-2'>Subscribe</button>
            </form>
          </div>
          <div className='text-[#646464] font-poppinsLight text-[14px] pt-4'>By subscribing, you agree with the Privacy Policy.</div>
        </div>
      </div>
      <div className=' font-poppinsLight text-[14px] pt-4 pb-10 border-t-2 border-[#F0F0F0] flex justify-between'>
        <div>A Simplif Company</div>
        <div>© Faboof Ltd</div>
      </div>
    </div>
  );
};

export default Footer;