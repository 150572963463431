import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import info_icon from '../assets/images/info_icon.png'


const UploadImageInfoModal = ({ open, handleClose }) => {

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '580px',
            borderColor: '#684BDB',
            borderRadius: '14px',
            borderWidth: '1px',
            padding: '10px',
            backgroundColor: '#5E2DD5'
          }
        }}
      >
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 20, right: 20, color:'white' }}
        >
            <CloseIcon style={{ fontSize: '35px' }} />
        </IconButton>
        <img src={info_icon} alt="Information" style={{ margin: '10px auto', marginTop: '30px', display: 'block' }} className="w-[150px]" />
        <div style={{ fontFamily: 'poppinsBold', textAlign: 'center' }}  className="text-white text-[17px] md:text-[30px]">
            Upload Image 1 & 2!
        </div>
        <DialogContent>
            <div className="my-1 md:my-4">
                <DialogContentText>
                    <div className="flex justify-center items-center font-poppinsLight text-[10px] md:text-[16px] text-white text-center">
                        Please Upload both images to proceed with the next step  
                    </div>
                </DialogContentText>
            </div> 
        </DialogContent>
        <DialogActions>
          
        </DialogActions>

      </Dialog>
  );
};

export default UploadImageInfoModal;
