import React from "react";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

const ContactUs = () => {
    return (
        <div className="container px-4 md:px-0">
            <Header />

            <div className="mt-10 md:mt-40">
                <h1 className="text-[16px] md:text-[47px] font-poppinsLight text-center">Couldn't find the answer your looking for?</h1>
                <h1 className="text-[20px] md:text-[47px] font-poppinsBold text-center">Contact our friendly team.</h1>
                <div className="mt-5 hidden md:block">
                    <h2 className="text-[30px] text-center font-poppinsExtraLight">We`re committed in creating an amazing experience to all out customers.</h2>
                    <h2 className="text-[30px] text-center font-poppinsExtraLight">If we`ve  fallen short of your expectations in any way, we want to hear about it</h2>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-10 p-4 md:p-36 mt-10'>
                    <div className="border border-[#E1E1E1] rounded-[12px] p-7">
                        <div className="">
                            <div className="font-poppinsBold text-[#0C0C0C] text-[12px] md:text-[24px]">Email Support</div>
                            <div className="font-poppinsLight text-[#0C0C0C] text-[10px] md:text-[14px] pt">We aim to reply within 48 hours</div>
                        </div>
                        <div className="mt-4 md:my-20">
                            <button className="border border-[#9333EA] rounded-[4px] text-[#9333EA] text-[10px] md:text-[14px] font-poppinsBold w-full py-4">Support@faboof.com</button>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="absolute right-10 top-[-35px]">
                            <div className="hidden md:block text-white bg-primary rounded py-2 px-4 font-poppinsBold text-[12px] p-1">FASTEST</div>
                        </div>
                        <div className="border border-[#E1E1E1] rounded-xl p-7">
                            <div>
                                <div className="font-poppinsBold text-[#0C0C0C] text-[12px] md:text-[24px]">Live Chat</div>
                                <div className="font-poppinsLight text-[#0C0C0C] text-[10px] md:text-[14px] pt">Our agents will aim to respond within a few hours</div>
                            </div>
                            <div className="mt-4 md:my-20">
                                <button className="bg-primary hover:bg-[#6E48D4] rounded text-white text-[10px] md:text-[14px] font-poppinsBold w-full py-4">Connect to Live chat</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <Footer />
        </div>
    );
}

export default ContactUs;