import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { LocalLaundryService } from "@mui/icons-material";
import { apis } from "../apis";

import { useToasts } from 'react-toast-notifications';

const PersonalDetail = () => {

    const navigate = useNavigate();

    const { addToast } = useToasts();
    
    const name = useSelector((state) => state.user.name);
    // const email = useSelector((state) => state.user.email);
    
    const [editEmail, setEditEmail] = useState('');
    
    const handleSaveEmail = async () => {

        try {

            const u_id = localStorage.getItem('Uid');

            const data = await apis.EditProfile({editEmail, u_id})

            if(data === 'edit_success')
                    addToast('Success: Updated profile Successfully', { appearance: 'success' });

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="container px-4 md:px-0">
            <Header />

            {/* personal detail form */}
            <div>
                <h1 className="text-[30px] text-[#0C0C0C] font-bold py-4 md:py-10">Personal Details</h1>
                <div className="flex">
                    <button className="text-white font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 bg-[#5E2DD5] text-white rounded-[3px]" onClick={() => navigate('/personalDetail', { replace: true })}>
                        Personal Details
                    </button> 
                    <button className="font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 border-[1px] border-[#C0B9B9] rounded-[3px]" onClick={() => navigate('/subscription', { replace: true })}>
                        Subscription
                    </button> 
                    <button className="font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 border-[1px] border-[#C0B9B9] rounded-[3px]" onClick={() => navigate('/downloads', { replace: true })}>
                        Downloads
                    </button> 
                </div>
                <div className="mt-10 w-full md:w-1/3">
                    <div className="mt-10">
                        <label className="block text-[#0C0C0C] text-[10px] md:text-[24px] font-poppinsBold mb-2" htmlFor="name">
                            Name
                        </label>
                        <input 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            id="name" 
                            type="text" 
                            placeholder="Name"
                            value={name}
                            readOnly />
                    </div>
                    <div className="mt-4 w-full">
                        <label className="block text-[#0C0C0C] text-[10px] md:text-[24px] font-poppinsBold mb-2" htmlFor="email">
                            Email
                        </label>
                        <div className="relative">
                            <input 
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="editEmail" 
                                type="text" 
                                placeholder="Edit Email" 
                                value={editEmail} 
                                onChange={(e) => setEditEmail(e.target.value)} 
                            />
                            <button className="text-primary font-poppinsBold text-[13.5px] py-2 px-4 rounded absolute right-0" onClick={() => { /* handle edit functionality */ }}>
                                Edit
                            </button>
                        </div>
                        <div className="w-full flex justify-end">
                            {
                                editEmail ? 
                                    <button className="bg-primary hover:bg-[#6E48D4] text-white font-bold text-[13.5px] py-2 px-4 rounded mt-4" onClick={() => handleSaveEmail() }>Save</button>
                                :   <button className="bg-[#979797] hover:bg-[#a8a8a8] text-white font-bold text-[13.5px] py-2 px-4 rounded mt-4" onClick={() => { /* handle save functionality */ }}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default PersonalDetail;