import axios from "axios";

const API = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
    timeout: 60000
});

API.interceptors.request.use(config => {
    // const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    // if (jwtToken) {
    //     config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
    // }

    return config
}, error => {
    // Do something with request error here
    Promise.reject(error)
})

// API respone interceptor
API.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    // let notificationParam = {
    //     message: ''
    // }

    // Remove token and redirect
    // if (unauthorizedCode.includes(error.response.status)) {
    // notificationParam.message = 'Authentication Fail'
    // notificationParam.description = error.response.data.message
    // localStorage.removeItem(AUTH_TOKEN)

    // store.dispatch(signOutSuccess())
    // }

    if (error.response.status === 404) {
        // notificationParam.message = 'Not Found'
    }

    if (error.response.status === 500) {
        // notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
        // notificationParam.message = 'Time Out'
    }

    // notification.error(notificationParam)

    return Promise.reject(error);
});

const Login = (data) => API.post('/login', data);
const Register = (data) => API.post('/register', data);
const EditProfile = (data) => API.post('/editProfile', data);
const LogOut = () => API.post('/logout');

export const apis = {
    Login,
    Register,
    EditProfile,
    LogOut
}