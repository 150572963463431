import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';

const ForgottenPasswordModal = ({ open, handleClose }) => {

  const handleForgottenPassword = (e) => {
    e.preventDefault();

    handleClose();
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { 
            backgroundColor: '#5E2DD5',
            color: 'white',
            width: '500px',
            borderRadius: '12px',
            padding: '10px'
          }
        }}
      >
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 20, right: 20 }}
        >
            <CloseIcon />
        </IconButton>
        <div style={{ fontFamily: 'poppinsBold', textAlign: 'center' }} className="text-[34px] md:text-[54px] mt-10">FABOOF</div>
        <DialogContent className="flex flex-col text-white">
          <div style={{ color: 'white', fontFamily: 'poppinsBold', textAlign: 'center' }}  className="text-[17px] md:text-[30px]">
            Forgotten Password?
          </div>
          <div className="text-center font-poppinsLight text-[10px] md:text-[16px]">Check your email and click on the link to reset your password.</div>
          <div className="mt-10">
            <div className="flex items-center relative">
              <input
                className="shadow appearance-none border border-[#EFEFEF] rounded-[8px] w-full py-4 pl-14 pr-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="inputEmail"
                type="email"
                placeholder="Enter Your Email"
                style={{ backgroundColor: '#5E2DD5', color: 'white' }}
              />
              <EmailIcon style={{ position: 'absolute', left: '20px', fontSize: '24px', color: 'white' }} />
            </div>
          </div>
          <div className="pt-10">
              <button
                  className="bg-[#B9B9B9] text-white w-full font-poppinsBold text-[14px] md:text-[24px] py-4 px-8 rounded-[4px] md:rounded-[8px]"
                  onClick={handleForgottenPassword}
              >
                  Resend reset email
              </button>
          </div> 
          <div className="text-center font-poppinsLight text-[12px] md:text-[16px] mt-4">Need help? <span className="font-poppinsBold text-[12px] md:text-[16px]">Contact Support</span></div>
        </DialogContent>
      </Dialog>
  );
};

export default ForgottenPasswordModal;
