import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import VisibilityIcon from '@mui/icons-material/Visibility';
import BrushIcon from '@mui/icons-material/Brush';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';

import Slider from '@mui/material/Slider';
import ProcessingModal from "../components/ProcessingModal";

import nike_whiteman from '../assets/images/nike_whiteman.jpg';
import blackman1 from '../assets/images/blackman1.png';

const MainWorkSpace = () => {

    const navigate = useNavigate();

    const [processingModal, setProcessingModal] = useState(false);

    const [brushSizeSlider, setBrushSizeSlider] = useState(false);
    const [brushSize, setBrushSize] = useState(30);

    const handleBrushSizeChange = (event, newValue) => {
        setBrushSize(newValue);
    };

    const handleProcessing = () => {
        setProcessingModal(true);

        setTimeout(() => {
            setProcessingModal(false);
            navigate('/preview', { replace: true });
        }, 3000);
    }

    return (
        <div className="container px-4 md:px-0">
            <Header />

            {/* Main workspace */}
            <div>
                <div className="mt-10 md:mt-20 flex justify-between items-center">
                    <h1 className="text-[36px] font-poppinsBold hidden md:block">Mask the area you wish to replace</h1>
                    <button className=" hidden md:block bg-[#ACACAC] hover:bg-[#BDBDBD] text-white text-[20px] md:text-[24px] font-poppinsBold py-2 px-10 md:px-20 rounded-[8px]" onClick={handleProcessing}>
                        Next
                    </button>
                    <div className="block md:hidden flex justify-end w-full">
                        <button className="py-2 mb-6 text-primary text-[11px] font-poppinsBold">
                            Change Image 2
                        </button>
                    </div>
                </div>
                <div className="mt-4 md:mt-10 flex relative">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-0 block md:hidden">
                        <div className="flex justify-center items-center h-full">
                            <svg width="120%" height="120%">
                                <defs>
                                    <pattern id="grid2" width="40" height="40" patternUnits="userSpaceOnUse">
                                        <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#8f8ff3" strokeWidth="1"/>
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" fill="url(#grid2)"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between h-96 z-20">
                        <button className=" bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px]"  onClick={() => navigate('/preview', { replace: true })}>
                            <VisibilityIcon style={{ fontSize: "40px" }} /><div>Preview</div>
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px] relative"  onClick={() => setBrushSizeSlider(!brushSizeSlider)}>
                            <BrushIcon style={{ fontSize: "40px" }} /><div>Brush Size</div>
                            {
                                brushSizeSlider && (
                                    <div className="absolute top-4 left-24 py-2 px-4 bg-white border border-primary rounded w-[200px]">
                                        <Slider
                                            value={brushSize}
                                            onChange={handleBrushSizeChange}
                                            valueLabelDisplay="auto"
                                            valueLabelFormat={(brushSize) => `${brushSize}%`}
                                            sx={{
                                                '& .MuiSlider-thumb': {
                                                    color: '#684BDB',
                                                },
                                                '& .MuiSlider-track': {
                                                    color: '#684BDB',
                                                },
                                                '& .MuiSlider-rail': {
                                                    color: '#ACACAC',
                                                },
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px]">
                            <RedoIcon style={{ fontSize: "40px" }} /><div>Redo</div>
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px]">
                            <UndoIcon style={{ fontSize: "40px" }} /><div>Undo</div>
                        </button>
                    </div>
                    <div className="md:bg-[#EEEEEE]  w-full ml-5 flex p-1 md:p-10 rounded-[10px] relative">
                        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 hidden md:block">
                            <div className="flex justify-center items-center h-full">
                                <svg width="95%" height="95%">
                                    <defs>
                                        <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
                                            <path d="M 80 0 L 0 0 0 80" fill="none" stroke="#8f8ff3" strokeWidth="1"/>
                                        </pattern>
                                    </defs>
                                    <rect width="100%" height="100%" fill="url(#grid)"/>
                                </svg>
                            </div>
                        </div>
                        <div className="w-5/12 z-10 hidden md:block">
                            <div className="font-poppinsBold text-[24px] text-center">Image 2</div>
                            <div className="flex justify-center mt-4">
                                <img src={nike_whiteman} alt="Nike Whiteman" width="80%" className="rounded-[8px] border border-[#DFDFDF]" />
                            </div>
                            <div className="flex justify-center">
                                <button className="bg-[#9C7EF0] hover:bg-primary text-white font-poppinsBold text-[13px] py-2 px-4 rounded-[4px] mt-4">
                                    Change Image 2
                                </button>
                            </div>
                        </div>
                        <div className="w-full md:w-7/12 z-10 relative">
                            <div className="block md:hidden absolute top-[-30px] font-poppinsBold text-[17px] md:text-[24px] md:text-center">Image 1</div>
                            <div className="hidden md:block font-poppinsBold text-[13px] md:text-[24px] md:text-center">Image 1</div>
                            <div className="flex justify-center md:mt-4">
                                <img src={blackman1} alt="blackman1" className="w-full md:w-4/5 rounded-[8px] border border-[#DFDFDF]" />
                            </div>
                            <div className="flex justify-center">
                                <button className="hidden md:block bg-[#9C7EF0] hover:bg-primary text-white font-poppinsBold text-[16px] md:text-[22px] py-2 px-4 rounded-[4px] mt-4">
                                    Change Image 1
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-8 block md:hidden">
                    <button className="text-[#9C7EF0] border border-[#9C7EF0] font-poppinsBold text-[16px] md:text-[22px] py-2 px-4 rounded-[4px] mt-10">
                        Change Image 1
                    </button>
                    <button className="mt-4 bg-[#ACACAC] hover:bg-[#BDBDBD] text-white text-[16px] md:text-[22px] font-poppinsBold py-2 px-10 md:px-20 rounded-[4px]" onClick={handleProcessing}>
                        Next
                    </button>
                </div>
            </div>
            
            <ProcessingModal open={processingModal} handleClose={() => setProcessingModal(false)} />

            <Footer />
        </div>
    );
}

export default MainWorkSpace;