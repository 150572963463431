import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BrushIcon from '@mui/icons-material/Brush';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import Slider from '@mui/material/Slider';

import blackman2 from '../assets/images/blackman2.png';

import DownloadPlanModal from "../components/DownloadPlanModal";

const Preview = () => {

    const navigate = useNavigate();

    const [brushSizeSlider, setBrushSizeSlider] = useState(false);
    const [brushSize, setBrushSize] = useState(30);

    const handleBrushSizeChange = (event, newValue) => {
        setBrushSize(newValue);
    };

    const [downloadPlanModal, setDownloadPlanModal] = useState(false);
    const handleDownload = () => {
        setDownloadPlanModal(true);
    }

    return (
        <div className="container px-4 md:px-0">
            <Header />

            {/* Main workspace */}
            <div>
                <div className="mt-10 md:mt-20 flex justify-between items-center">
                    <h1 className="text-[36px] font-poppinsBold hidden md:block">Preview</h1>
                    <div className="flex hidden md:block">
                        <button className="text-primary border border-primary font-poppinsBold text-[16px] md:text-[24px] py-2 px-4 rounded-[8px]" onClick={() => navigate('/mainWorkSpace', { replace: true })}>
                            Return to Edit
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsBold text-[16px] md:text-[24px] py-2 px-8 rounded-[8px] ml-10" onClick={handleDownload}>
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-4 md:mt-10 flex relative">
                    <div className="flex flex-col justify-between h-96 z-20">
                        <button className="bg-[#B9B9B9] hover:bg-[#A3A3A3] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px]"  onClick={() => navigate('/preview', { replace: true })}>
                            <VisibilityOffIcon style={{ fontSize: "40px" }} /><div>Preview</div>
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px] relative"  onClick={() => setBrushSizeSlider(!brushSizeSlider)}>
                            <BrushIcon style={{ fontSize: "40px" }} /><div>Brush Size</div>
                            {
                                brushSizeSlider && (
                                    <div className="absolute top-4 left-24 py-2 px-4 bg-white border border-primary rounded w-[200px]">
                                        <Slider
                                            value={brushSize}
                                            onChange={handleBrushSizeChange}
                                            valueLabelDisplay="auto"
                                            valueLabelFormat={(brushSize) => `${brushSize}%`}
                                            sx={{
                                                '& .MuiSlider-thumb': {
                                                    color: '#684BDB',
                                                },
                                                '& .MuiSlider-track': {
                                                    color: '#684BDB',
                                                },
                                                '& .MuiSlider-rail': {
                                                    color: '#ACACAC',
                                                },
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px]">
                            <RedoIcon style={{ fontSize: "40px" }} /><div>Redo</div>
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsLight text-[13px] p-2 w-[90px] h-[90px] rounded-[10px]">
                            <UndoIcon style={{ fontSize: "40px" }} /><div>Undo</div>
                        </button>
                    </div>
                    <div className="md:bg-[#EEEEEE] w-full ml-5 flex justify-center p-1 md:p-10 rounded-[10px] relative">
                        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 hidden md:block">
                            <div className="flex justify-center items-center h-full">
                                <svg width="95%" height="95%">
                                    <defs>
                                        <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
                                            <path d="M 80 0 L 0 0 0 80" fill="none" stroke="#8f8ff3" strokeWidth="1"/>
                                        </pattern>
                                    </defs>
                                    <rect width="100%" height="100%" fill="url(#grid)"/>
                                </svg>
                            </div>
                        </div>
                        <div className="flex justify-center md:mt-10 z-10">
                            <img src={blackman2} alt="blackman1" className="w-full md:w-4/5 rounded-[10px]" />
                        </div>
                    </div>
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-0 block md:hidden">
                        <div className="flex justify-center items-center h-full">
                            <svg width="110%" height="110%">
                                <defs>
                                    <pattern id="grid2" width="40" height="40" patternUnits="userSpaceOnUse">
                                        <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#8f8ff3" strokeWidth="1"/>
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" fill="url(#grid2)"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-10 block md:hidden">
                    <button className="text-primary border border-primary font-poppinsBold text-[16px] md:text-[24px] py-2 px-4 rounded-[8px]" onClick={() => navigate('/mainWorkSpace', { replace: true })}>
                        Return to Edit
                    </button>
                    <button className="mt-4 bg-primary hover:bg-[#6E48D4] text-white font-poppinsBold text-[16px] md:text-[24px] py-2 px-8 rounded-[8px]" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>

            <DownloadPlanModal open={downloadPlanModal} handleClose={() => setDownloadPlanModal(false)} />

            <Footer />
        </div>
    );
}

export default Preview;