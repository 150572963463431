import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import success_icon from '../assets/images/success_icon.png'


const DowngradeSuccessModal = ({ open, handleClose }) => {

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '580px',
            borderColor: '#684BDB',
            borderRadius: '14px',
            borderWidth: '1px',
            padding: '10px',
          }
        }}
      >
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 20, right: 20, color:'#7F57F1' }}
        >
            <CloseIcon style={{ fontSize: '35px' }} />
        </IconButton>
        <img src={success_icon} alt="Information" style={{ margin: '10px auto', marginTop: '30px', display: 'block' }} className="w-[150px]" />
        <div style={{ color: '#7F57F1', fontFamily: 'poppinsBold', textAlign: 'center' }}  className="text-[17px] md:text-[30px]">
            Successfully Downloaded
        </div>
        <DialogContent>
            <div className="my-1 md:my-4">
                <DialogContentText>
                <div className="flex justify-center items-center font-poppinsLight text-[10px] md:text-[16px] text-primary text-center">
                        Remember  you can always download this at anytime again in the download library within 30 days 
                    </div>
                </DialogContentText>
            </div>
            <div>
                <button
                    className="bg-primary text-white w-full font-poppinsBold text-[14px] md:text-[24px] py-4 px-8 rounded-[4px] md:rounded-[8px] mt-4"
                    onClick={handleClose}
                >
                    Try another image
                </button>
            </div>  
        </DialogContent>
        <DialogActions>
          
        </DialogActions>

      </Dialog>
  );
};

export default DowngradeSuccessModal;
