import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import UpgradePlanModal from "./UpgradePlanModal";
import ProcessingModal from "./ProcessingModal";
import DowngradeSuccessModal from "./DownloadSuccessModal";

const DowngradePlanCard = ({ open, handleClose }) => {

    const [upgradeModal, setUpgradeModal] = useState(false);
    const [processingModal, setProcessingModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const handleUpgrade = () => {
        setUpgradeModal(true);
    }

    const handleDownload = () => {

        setProcessingModal(true);

        setTimeout(() => {
            setProcessingModal(false);
            
            setSuccessModal(true);
        }, 3000);
    }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '580px',
            borderColor: '#684BDB',
            borderRadius: '14px',
            borderWidth: '1px',
          }
        }}
      >
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 20, right: 20, color:'white' }}
        >
            <CloseIcon style={{ fontSize: '35px' }} />
        </IconButton>
        <div className="">
            <div className="bg-[#5E2DD5] p-4 md:p-10 text-white rounded-[14px]">
                <VerifiedIcon style={{ fontSize: '40px' }} />
                <div className="font-poppinsBold text-[14px] md:text-[28px] mt-4 md:mt-8">Unlock all features with Premium</div>
                <div className="font-poppinsLight text-[9px] md:text-[16px]">Unleash the full power of changing clothes</div>
                <div className="flex items-center m-2 md:m-4">
                    <h1 className="text-[32px] md:text-[56px] font-poppinsBold">£4.99</h1>
                    <div className="text-[9px] md:text-[16px] font-poppinsBold">/month</div>
                </div>
                <div className="mt-4 md:mt-8">
                    <div className="flex items-center m-2 md:mt-4">
                        <CheckCircleIcon style={{ fontSize: '30px' }}  />
                        <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Unlimited Daily Downloads</div>
                    </div>
                    <div className="flex items-center m-2 md:mt-4">
                        <CheckCircleIcon style={{ fontSize: '30px' }}  />
                        <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Remove Water mark</div>
                    </div>
                    <div className="flex items-center m-2 md:mt-4">
                        <CheckCircleIcon style={{ fontSize: '30px' }}  />
                        <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Remove Wait time</div>
                    </div>
                    <div className="flex items-center m-2 md:mt-4">
                        <CheckCircleIcon style={{ fontSize: '30px' }}  />
                        <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Instant HD Download</div>
                    </div>
                </div>
                <button className="mt-8 bg-primary text-white w-full font-poppinsBold text-[14px] md:text-[24px] border border-white rounded-[4px] py-2 px-4 md:rounded-[8px]" onClick={handleUpgrade}>Upgrade Plan</button>
                <button className="mt-4 bg-[#2E2E2E] text-white w-full font-poppinsBold text-[14px] md:text-[24px] border border-white py-2 px-4 rounded-[4px] md:rounded-[8px]" onClick={handleDownload}>Continue Free Download</button>
                <div className="text-[12px] text-center font-poppinsLight mt-4">
                    Cancel Subscription at Anytime 
                </div>
            </div>
        </div>

        <UpgradePlanModal open={upgradeModal} handleClose={() => setUpgradeModal(false)} />
        
        <ProcessingModal open={processingModal} handleClose={() => setProcessingModal(false)} />
        
        <DowngradeSuccessModal open={successModal} handleClose={() => setSuccessModal(false)} />

      </Dialog>
  );
};

export default DowngradePlanCard;
