import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";


const ProcessingModal = ({ open, handleClose }) => {

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '330px',
            borderColor: '#684BDB',
            borderRadius: '8px',
            borderWidth: '1px',
            padding: '10px'
          }
        }}
      >
        <DialogTitle style={{ fontFamily: 'poppinsBold', color: '#6A6B6C', fontSize: '14px', textAlign: 'center' }}>Estimated 3 Seconds</DialogTitle>
        <DialogContent>
          <div className="my-4">
            <DialogContentText>
                <div className="flex justify-center items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </DialogContentText>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-4">
                <div className="bg-primary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: '70%' }}> 70%</div>
            </div>
            </div>
            <div className="text-center font-poppinsLight text-[12px]">Skip the wait. <Link to='/subscription'><span className="text-primary font-poppinsBold">Get Premium</span></Link></div>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>

      </Dialog>
  );
};

export default ProcessingModal;
