import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { apis } from "../apis";

import ForgottenPasswordModal from "./ForgottenPasswordModal";

import { useToasts } from 'react-toast-notifications';
import { setEmail, setLoggedIn, setUid, setName } from "../redux/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";


const SignInModal = ({ open, handleClose }) => {

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const [rememberMe, setRememberMe] = useState(false);

  const [forgottenPassword, setForgottenPassword] = useState(false);

  const handleSignIn = async (e) => {
    try {
      e.preventDefault();

      const email = document.getElementById('inputEmail').value;
      const password = document.getElementById('inputPassword').value;

      const data = await apis.Login({ email: email, password: password });

      if (data === 'login_failed') {
        addToast('Error: Wrong authentication !', { appearance: 'error' });
      } else if (data === 'Try Login') {

        addToast('Warning: Please input the correct information !', { appearance: 'warning' });

      } else {

        addToast('Success: Logged In Successfully', { appearance: 'success' });

        handleClose();

        localStorage.setItem('userLoggedIn', JSON.stringify(data[0]));
        localStorage.setItem('Uid', JSON.stringify(data[1]));
        localStorage.setItem('userName', JSON.stringify(data[2]));
        localStorage.setItem('userEmail', JSON.stringify(data[3]));

        dispatch(setLoggedIn(data[0]));
        dispatch(setUid(data[1]));
        dispatch(setName(data[2]));
        dispatch(setEmail(data[3]));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignUp = () => {

  }

  const handleForgotPassword = () => {
    // Add logic for handling forgot password action
    console.log("Forgot Password clicked");

    setForgottenPassword(true);
  };

  useEffect(() => {


  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#5E2DD5',
          color: 'white',
          width: '500px',
          borderRadius: '12px',
          padding: '10px'
        }
      }}
      style={{ zIndex: 900 }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        sx={{ position: 'absolute', top: 20, right: 20, color: 'white' }}
      >
        <CloseIcon style={{ fontSize: '35px' }} />
      </IconButton>
      <div style={{ fontFamily: 'poppinsBold', textAlign: 'center' }} className="text-[34px] md:text-[54px] mt-10">FABOOF</div>
      <DialogContent className="flex flex-col text-white">
        <div style={{ color: 'white', fontFamily: 'poppinsBold', textAlign: 'center' }} className="text-[17px] md:text-[30px]">
          Welcome to FABOOF
        </div>
        <div className="text-center font-poppinsLight text-[10px] md:text-[16px]">Please Login to continue </div>
        <div className="flex flex-col py-4 md:px-4">
          <div className="mt-4">
            <div className="flex items-center relative">
              <input
                className="shadow appearance-none border border-[#EFEFEF] rounded-[8px] w-full py-4 pl-14 pr-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="inputEmail"
                type="email"
                placeholder="Enter Your Email"
                style={{ backgroundColor: '#5E2DD5', color: 'white' }}
              />
              <EmailIcon style={{ position: 'absolute', left: '20px', fontSize: '24px', color: 'white' }} />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center relative">
              <input
                className="shadow appearance-none border border-[#EFEFEF] rounded-[8px] w-full py-4 pl-14 pr-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="inputPassword"
                type="password"
                placeholder="Enter Your Password"
                style={{ backgroundColor: '#5E2DD5', color: 'white' }}
              />
              <LockIcon style={{ position: 'absolute', left: '20px', fontSize: '24px', color: 'white' }} />
            </div>
          </div>

          <div className="flex justify-between items-center mt-4">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: 'white', // Set custom color
                    '&.Mui-checked': {
                      color: 'white', // Set custom color when checked
                    }
                  }}
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              label="Remember Me"
            />
            <div>
              <button onClick={handleForgotPassword} className="text-[#EFEFEF] font-poppinsLight text-[10px] md:text-[16px]">Forgot Password</button>
            </div>
          </div>
        </div>
        <div className="py-4 md:px-4">
          <button
            className="bg-primary text-white w-full font-poppinsBold text-[14px] md:text-[24px] border border-white py-4 px-8 rounded-[6px] md:rounded-[8px]"
            onClick={handleSignIn}
          >
            Sign In
          </button>
        </div>
        <div className="text-center font-poppinsLight text-[12px] md:text-[16px]">Already Have an Account? <button className="font-poppinsBold text-[12px] md:text-[18px]" onClick={handleSignUp}>Sign Up</button></div>
      </DialogContent>

      <ForgottenPasswordModal open={forgottenPassword} handleClose={() => setForgottenPassword(false)} />
    </Dialog>
  );
};

export default SignInModal;
