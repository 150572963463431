import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setLoggedIn, setUid, setName } from "../redux/slices/userSlice";

import SignUpModal from '../components/SignUpModal';
import SignInModal from '../components/SignInModal';

import Avatar_icon from '../assets/images/avatar_icon.png';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import LogoutIcon from '@mui/icons-material/Logout';

import { useToasts } from 'react-toast-notifications';
import { apis } from '../apis';

const Header = () => {

  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);

  const [openUserDropdown, setOpenUserDropdown] = useState(false);

  const loggedIn = useSelector((state) => state.user.loggedIn);
  const loggedUserName = useSelector((state) => state.user.name);
  // const loggedIn = localStorage.getItem('userLoggedIn');
  // const loggedUserName = localStorage.getItem('userName');


  const handleLogout = async () => {

    try {

      const data = await apis.LogOut();

      if(data === 'logout') {
        addToast('Info : Log out !', { appearance: 'info' });

        dispatch(setLoggedIn(false));
        dispatch(setUid(''));
        dispatch(setName(''));
        dispatch(setEmail(''));

        localStorage.removeItem('Uid');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userName');
        localStorage.removeItem('userLoggedIn');

        setOpenUserDropdown(!openUserDropdown)
      }
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {

    // const localStorage_loggedIn = localStorage.getItem('userLoggedIn');
    // const localStorage_uid = localStorage.getItem('Uid');
    // const localStorage_username = localStorage.getItem('userName');
    // const localStorage_useremail = localStorage.getItem('userEmail');
    
    // dispatch(setLoggedIn(localStorage_loggedIn));
    // dispatch(setUid(localStorage_uid));
    // dispatch(setName(localStorage_username));
    // dispatch(setEmail(localStorage_useremail));

    // console.log(loggedIn)

  }, []);

  return (
    <div className="flex justify-between items-center py-2 md:py-4">
      
      <Link to='/'><h1 className="text-[24px] md:text-5xl text-primary  font-[1000]">FABOOF</h1></Link>
      <div>
        {
          loggedIn === true ? 
              <div className='flex items-center' >
                <div className='text-[14px] md:text-[20px] font-poppinsBold'>{loggedUserName}</div>
                <div className='relative'>
                  <button className="flex items-center py-2 px-4" onClick={() => setOpenUserDropdown(!openUserDropdown)}>
                    <img src={Avatar_icon} alt="Avatar" className="h-12 w-12" />
                  </button>
                  {openUserDropdown && (
                      <div className="absolute left-[-100px] z-20 md:left-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
                          <Link to='/personalDetail'>
                            <button className="block w-full py-2 px-4 text-gray-800 hover:bg-gray-100 text-left">
                                <AssignmentIndIcon /><span className='ml-3'>Personal Details</span>
                            </button>
                          </Link>
                          <Link to='/subscription'>
                            <button className="block w-full py-2 px-4 text-gray-800 hover:bg-gray-100 text-left">
                                <SubscriptionsIcon /><span className='ml-3'>Subscription</span>
                            </button>
                          </Link>
                          <Link to='/downloads'>
                            <button className="block w-full py-2 px-4 text-gray-800 hover:bg-gray-100 text-left">
                                <CloudDownloadIcon /><span className='ml-3'>Downloads</span>
                            </button>
                          </Link>
                          <Link to='' >
                            <button className="block w-full py-2 px-4 text-gray-800 hover:bg-gray-100 text-left" onClick={() => handleLogout()}>
                                <LogoutIcon /><span className='ml-3'>Logout</span>
                            </button>
                          </Link>
                      </div>
                  )}
                </div>
              </div>
          : <div>
              <button className="font-poppinsBold text-[10px] md:text-[20px] py-2 px-4 mr-4"   onClick={() => setOpenSignInModal(true)}>
                  Log In
              </button>
              <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsBold text-[10px] md:text-[20px] py-2 px-4 rounded-[3px] md:rounded-[12px]" onClick={() => setOpenSignUpModal(true)}>
                  Start For Free
              </button>
            </div>
        }  
        <SignInModal open={openSignInModal} handleClose={() => setOpenSignInModal(false)} />
        <SignUpModal open={openSignUpModal} handleClose={() => setOpenSignUpModal(false)} />
      </div>
    </div>
  );
};

export default Header;