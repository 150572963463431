import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import store from './redux/store';

import Home from './pages/Home';
import MainWorkSpace from './pages/MainWorkSpace';
import Preview from './pages/Preview';
import PersonalDetail from './pages/PersonalDetail';
import Subscription from './pages/Subscription';
import Downloads from './pages/Downloads';
import ContactUs from './pages/ContactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ToastProvider 
    autoDismiss 
    autoDismissTimeout={3000}
    // placement='bottom-center'
  >
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="mainWorkSpace" element={<MainWorkSpace />} />
            <Route path="preview" element={<Preview />} />
            <Route path="personalDetail" element={<PersonalDetail />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="downloads" element={<Downloads />} />
            <Route path="contact" element={<ContactUs />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </ToastProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
