import React from "react";

const WorkStep = ({ image, title, content }) => {
    return(
        <div className="bg-[#00000008] p-4 rounded-xl grid grid-cols-2 md:grid-cols-1">
            <div className="w-full">
                <img src={image} alt="Nike Whiteman" className="w-full" />
            </div>
            <div className="p-2 flex flex-col justify-start item-center">
                <div className="text-[16px] md:text-[20px] font-poppinsBold pt-4">{title}</div>
                <div className="text-[12px] md:text-[16px] font-poppinsLight md:font-poppinsExtraLight text-[#2B2929A1] pt-2">{content}</div>
            </div>
        </div>
    );
}

export default WorkStep;