import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import '../assets/css/home.css';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import WorkStep from '../components/workStep';

import blackman1 from '../assets/images/blackman1.png';
import blackman2 from '../assets/images/blackman2.png';
import a1 from '../assets/images/a1.png';
import a2 from '../assets/images/a2.png';
import b1 from '../assets/images/b1.png';
import b2 from '../assets/images/b2.png';
import c1 from '../assets/images/c1.png';
import c2 from '../assets/images/c2.png';
import step1 from '../assets/images/step1.png';
import step2 from '../assets/images/step2.png';
import step3 from '../assets/images/step3.png';
import step4 from '../assets/images/step4.png';
import underline from '../assets/images/underline.png';
import add_icon from '../assets/images/add_icon.png';

import UploadImageInfoModal from '../components/UploadImageInfoModal';

import ReactCompareImage from 'react-compare-image';

import { useDropzone } from 'react-dropzone';

const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize(window.innerHeight);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Home = () => {
    const height = useWindowSize();
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    const imageSets = [[a1, a2], [b1, b2], [c1, c2], [blackman1, blackman2]];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((currentIndex + 1) % imageSets.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex]);

    const [uploadImageInfoModal, setUploadImageInfoModal] = useState(false);

    const uploadImage = () => {
        // Swal.fire({
        //     title: 'Upload Image 1 & 2!',
        //     text: 'Please Upload both images to proceed with the next step',
        //     icon: 'info',
        //     showCloseButton: true,
        //     customClass: {
        //         closeButton: 'custom-close-button-class'
        //     },
        //     showConfirmButton: false,
        // });

        setUploadImageInfoModal(true);
    }

    const [originalImage, setOriginalImage] = useState(null);
    const [newClothingImage, setNewClothingImage] = useState(null);

    const onDrop1 = useCallback((acceptedFiles) => {
        // Assuming only image
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setOriginalImage(reader.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Assuming only image
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setNewClothingImage(reader.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({ onDrop: onDrop1 });
    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({ onDrop: onDrop2 });

    useEffect(() => {
        if (containerRef && containerRef.current) {
            setContainerHeight(containerRef.current.offsetWidth)
        }
    }, [containerRef, height]);

    return (
        <div className="container px-4 md:px-0">

            <Header />

            {/* Main banner */}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-10 pt-4 md:pt-20'>
                <div className='text-left'>
                    <div className='relative'>
                        <h1 className="text-[40px] md:text-[70px] font-syneBold">Change Outfits in <br />Seconds</h1>
                        <div className='absolute bottom-[-10px] w-[180px] md:w-[330px]'>
                            <img src={underline} alt="underline" width='100%' />
                        </div>
                    </div>
                    <h6 className='text-[14px] md:text-[20px] font-syneRegular mt-10 '>Unlock the power of changing outifts at your fingertips with Faboof</h6>
                    <div className='flex '>
                        <button className="hidden md:block bg-[#161616] hover:bg-[#262626] text-white font-poppinsBold text-[16px] py-2 px-3 md:py-4 md:px-9 rounded-[6px] mt-10">
                            <a href="#howItWorksSection">How it works?</a>
                        </button>
                        <button className="bg-primary hover:bg-[#6E48D4] text-white font-poppinsBold text-[16px] py-2 px-3 md:py-4 md:px-10 rounded-[6px] mt-10 md:ml-10" onClick={() => navigate('/mainWorkSpace')}>
                            {/* <a href='#getStarted'>Get started</a> */}
                            Get started
                        </button>
                    </div>
                </div>
                <div>
                    <div className={`w-10/12 relative m-auto`} style={{ height: containerHeight * 1.323 }}>
                        {imageSets.map((img, index) => (
                            <div
                                key={index}
                                className={`w-full absolute top-0 ${currentIndex === index ? "z-10" : ""}`}
                                ref={currentIndex === 0 ? containerRef : null}
                            >
                                <ReactCompareImage

                                    aspectRatio='wider'
                                    leftImage={img[0]}
                                    rightImage={img[1]}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Image upload */}
            <div className='pt-10 md:pt-20' id='getStarted'>
                <h1 className='text-[24px] md:text-[48px] font-poppinsBold font-bold'>Get Started</h1>
                <div className='grid grid-cols-2 gap-10 pt-10 md:pt-20'>
                    <div>
                        <div className='flex'>
                            <div className='w-[45px] md:w-[56px] h-[25px] md:h-[56px] text-white font-bold bg-primary rounded-full flex justify-center items-center text-[14px] md:text-2xl'>1</div>
                            <div className='ml-4 h-[60px] flex flex-col justify-between'>
                                <div className='text-primary text-[10px] md:text-[24px] font-poppinsBold'>Upload Original Image</div>
                                <div className='font-poppinsLight text-[8px] md:text-[16.5px] text-[#848484]'>This is the base image for swapping new clothing items with.</div>
                            </div>
                        </div>
                        <div {...getRootProps1()} className="dropzone bg-[#f9f7fe] relative p-1 md:p-5 mt-4 md:mt-10 h-[170px] md:h-[570px]" style={{ border: '2px dashed #7F57F1', borderRadius: '10px', cursor: 'pointer' }}>
                            <input {...getInputProps1()} accept="image/*" />
                            {originalImage && (
                                <img src={originalImage} alt="originalImage" style={{ height: '100%' }} />
                            )}
                            <div className='flex flex-col items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <img src={add_icon} alt="Add Icon" className="w-5 h-5 md:w-20 md:h-20 my-4" />
                                <p className='font-poppinsBold text-[9px] md:text-[24px] text-[#7F57F1] text-center'>Upload Original Image To Swap</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='w-[35px] md:w-[56px] h-[25px] md:h-[56px] text-white font-bold bg-primary rounded-full flex justify-center items-center text-[14px] md:text-2xl'>2</div>
                            <div className='ml-4 h-[60px] flex flex-col justify-between'>
                                <div className='text-primary text-[10px] md:text-[24px] font-poppinsBold'>Upload Target Clothing</div>
                                <div className='font-poppinsLight text-[8px] md:text-[16.5px] text-[#848484]'>Swap New Clothing Item From Original Image</div>
                            </div>
                        </div>
                        <div {...getRootProps2()} className="dropzone bg-[#f9f7fe] relative p-1 md:p-5 mt-4 md:mt-10 h-[170px] md:h-[570px]" style={{ border: '2px dashed #7F57F1', borderRadius: '10px', cursor: 'pointer' }}>
                            <input {...getInputProps2()} accept="image/*" />
                            {newClothingImage && (
                                <img src={newClothingImage} alt="newClothingImage" style={{ height: '100%' }} />
                            )}
                            <div className='flex flex-col items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <img src={add_icon} alt="Add Icon" className="w-5 h-5 md:w-20 md:h-20 my-4" />
                                <p className='font-poppinsBold text-[9px] md:text-[24px] text-[#7F57F1] text-center'>Upload New Clothing Item</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end mt-10'>
                    {
                        originalImage && newClothingImage ?
                            <button onClick={uploadImage} className="bg-[#7F57F1] hover:bg-[#6E48D4] text-white text-[14px] md:text-[24px] font-poppinsBold py-2 px-16 rounded-[4px] md:rounded-[8px]">Next</button>
                            : <button onClick={uploadImage} className="bg-[#979797] hover:bg-[#a8a8a8] text-white text-[14px] md:text-[24px] font-poppinsBold py-2 px-16 rounded-[4px] md:rounded-[8px]">Next</button>
                    }
                </div>
            </div>

            {/* How it works? */}
            <div className='pt-10 md:pt-20' id="howItWorksSection">
                <h1 className='text-[24px] md:text-[48px] font-poppinsBold'>How it works?</h1>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-10 pt-10 md:pt-20'>
                    <WorkStep image={step1} title="1. Upload Original Image" content="This is the base image for swapping new clothing items" />
                    <WorkStep image={step2} title="2. Upload New clothing Image" content="This is the new item you would like to wear" />
                    <WorkStep image={step3} title="3. Draw Mask" content="Mask over the clothing item you would like to replace" />
                    <WorkStep image={step4} title="4. Download your new outfit" content="Voila! Now you have a new virtual outfit" />
                </div>
            </div>

            <UploadImageInfoModal open={uploadImageInfoModal} handleClose={() => setUploadImageInfoModal(false)} />

            <Footer />

        </div>
    );
};

export default Home;