import { createSlice } from '@reduxjs/toolkit';

const localStorage_loggedIn = localStorage.getItem('userLoggedIn');
const localStorage_uid = localStorage.getItem('Uid');
const localStorage_username = localStorage.getItem('userName');
const localStorage_useremail = localStorage.getItem('userEmail');

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: localStorage_username || '',
    uid: localStorage_uid || '',
    email: localStorage_useremail || '',
    loggedIn: localStorage_loggedIn || false,
  },
  reducers: {
    setUid: (state, action) => {
      state.uid = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
   },
})

// Action creators are generated for each case reducer function
export const { setUid, setName, setEmail, setLoggedIn } = userSlice.actions

export default userSlice.reducer