import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import VerifiedIcon from '@mui/icons-material/Verified';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';

import DowngradePlanModal from "../components/DowngradePlanModal";

const Subscription = () => {

    const navigate = useNavigate();

    const [downgradePlanModal, setDowngradePlanModal] = useState(false);

    const handleDowngrade = () => {
        // Swal.fire({
        //     title: 'Would you like to Downgrade?',
        //     text: "By continuing to downgrade, you'll loose access to premium features and be switched to the free standard plan.",
        //     icon: 'info',
        //     showCloseButton: true,
        //     customClass: {
        //         closeButton: 'custom-close-button-class'
        //     },
        //     showConfirmButton: true,
        //     confirmButtonText: 'Downgrade',
        //     confirmButtonColor: '#3085d6',
        //     showCancelButton: true,
        //     cancelButtonText: 'Cancel',
        //     cancelButtonColor: '#6c757d',
        // });

        setDowngradePlanModal(true);
    }

    return (
        <div className="container px-4 md:px-0">
            <Header />
            
            {/* subscription form */}
            <div>
                <h1 className="text-[30px] text-[#0C0C0C] font-poppinsBold py-4 md:py-10">Subscriptions</h1>
                <div className="flex">
                    <button className="font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 border-[1px] border-[#C0B9B9] rounded-[3px]" onClick={() => navigate('/personalDetail', { replace: true })}>
                        Personal Details
                    </button> 
                    <button className="text-white font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 bg-[#5E2DD5] text-white rounded-[3px]" onClick={() => navigate('/subscription', { replace: true })}>
                        Subscription
                    </button> 
                    <button className="font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 border-[1px] border-[#C0B9B9] rounded-[3px]" onClick={() => navigate('/downloads', { replace: true })}>
                        Downloads
                    </button> 
                </div>
                <div className="mt-10 w-full md:w-1/4">
                    <div className="mt-10">
                        <label className="block text-[#0C0C0C] font-poppinsBold text-[10px] md:text-[24px] mb-2" htmlFor="plan">
                            Current Plan
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="plan" type="text" placeholder="Basic Plan" readOnly />
                    </div>
                    <div className="mt-4">
                        <label className="block text-[#0C0C0C] font-poppinsBold text-[10px] md:text-[24px] mb-2" htmlFor="usage_used">
                            Usage Used 
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="usage_used" type="text" placeholder="0 / 4 Downloads" readOnly />
                    </div>
                    <div className="mt-4 text-center font-poppinsLight text-[9px] md:text-[13px]">Upgrade to premium for <span className="text-primary font-bold">Unlimited Downloads</span></div>
                </div>
            </div>

            {/* subscription cards */}
            <div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-10 pt-10 md:pt-20'>
                    <div className="flex justify-center">
                        <div className="bg-[#5E2DD5] p-4 md:p-10 text-white rounded-[14px] md:rounded-[24px] w-full md:w-4/5">
                            <VerifiedIcon style={{ fontSize: '40px' }} />
                            <div className="font-poppinsBold text-[14px] md:text-[28px] mt-4 md:mt-8">Unlock all features with Premium</div>
                            <div className="font-poppinsLight text-[9px] md:text-[16px]">Unleash the full power of changing clothes</div>
                            <div className="flex items-center m-2 md:m-4">
                                <h1 className="text-[32px] md:text-[56px] font-poppinsBold">£4.99</h1>
                                <div className="text-[9px] md:text-[16px] font-poppinsBold">/month</div>
                            </div>
                            <div className="mt-4 md:mt-8">
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Unlimited Daily Downloads</div>
                                </div>
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Remove Water mark</div>
                                </div>
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Remove Wait time</div>
                                </div>
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Instant HD Download</div>
                                </div>
                            </div>
                            <button className="mt-8 bg-primary text-white w-full font-poppinsBold text-[14px] md:text-[24px] border border-white rounded-[4px] py-2 px-4 md:rounded-[8px]">Upgrade Plan</button>
                            <div className="text-[12px] text-center font-poppinsLight mt-4">
                                Cancel Subscription at Anytime 
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="bg-[#C0C0C0] p-4 md:p-10 text-white rounded-[14px] md:rounded-[24px] w-full md:w-4/5">
                            <FavoriteIcon style={{ fontSize: '40px' }} />
                            <div className="font-poppinsBold text-[14px] md:text-[28px] mt-4 md:mt-8">Basic Plan</div>
                            <div className="font-poppinsLight text-[9px] md:text-[16px]">Unleash the full power of changing clothes</div>
                            <div className="flex items-center m-2 md:m-4">
                                <h1 className="text-[32px] md:text-[56px] font-poppinsBold">£0</h1>
                                <div className="text-[9px] md:text-[16px] font-poppinsBold">/month</div>
                            </div>
                            <div className="mt-4 md:mt-8">
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon  style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Only 4 Weekly Downloads </div>
                                </div>
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon  style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Water mark on Image</div>
                                </div>
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon  style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Long Wait time</div>
                                </div>
                                <div className="flex items-center m-2 md:mt-4">
                                    <CheckCircleIcon  style={{ fontSize: '30px' }}  />
                                    <div className="text-[14px] md:text-[26px] font-poppinsLight ml-2">Basic Download Quality</div>
                                </div>
                            </div>
                            <button className="mt-8 bg-[#737178] text-white w-full font-poppinsBold text-[14px] md:text-[24px] border border-white py-2 px-4 rounded-[4px] md:rounded-[8px]" onClick={handleDowngrade}>Downgrade</button>
                            <div className="text-[12px] text-center font-poppinsLight mt-4">
                                Cancel Subscription at Anytime 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DowngradePlanModal open={downgradePlanModal} handleClose={() => setDowngradePlanModal(false)} />

            <Footer />
        </div>
    );
}

export default Subscription;