import React from "react";
import { useNavigate } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import blackman2 from '../assets/images/blackman2.png';

const Downloads = () => {

    const paginationStyle = {
        '& .MuiPaginationItem-root': {
          color: '#7F57F1',
          width: '30px',
          height: '30px',
        },
        '& .MuiPaginationItem-page.Mui-selected': {
          backgroundColor: '#DFD5FB',
        },
    };

    const navigate = useNavigate();

    return (
        <div className="container px-4 md:px-0">
            <Header />
            
            <div>
                <h1 className="text-[30px] text-[#0C0C0C] font-bold py-4 md:py-10">Downloads</h1>
                <div className="flex">
                    <button className="font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 border-[1px] border-[#C0B9B9] rounded-[3px]" onClick={() => navigate('/personalDetail', { replace: true })}>
                        Personal Details
                    </button> 
                    <button className="font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 border-[1px] border-[#C0B9B9] rounded-[3px]" onClick={() => navigate('/subscription', { replace: true })}>
                        Subscription
                    </button> 
                    <button className="text-white font-poppinsBold text-[9px] md:text-[13.5px] py-2 px-4 mr-4 bg-[#5E2DD5] text-white rounded-[3px]" onClick={() => navigate('/downloads', { replace: true })}>
                        Downloads
                    </button> 
                </div>
            </div>
            <div className="text-[10px] md:text-[18px] font-poppinsLight text-[#0C0C0C] mt-4 md:mt-8">Images on this Download library will be available for 30 days</div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-10 mt-4 md:mt-20'>
                <div className="mt-10">
                    <img src={blackman2} alt="Description1" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description2" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description3" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description4" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description5" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description6" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description7" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <img src={blackman2} alt="Description8" width='100%' className="rounded-[10px]" />
                    <div className="flex justify-center mt-4">
                        <button className="py-2 px-4 font-poppinsBold text-[8px] md:text-[14px] mr-4 bg-primary text-white rounded-[4px]">
                            Download
                        </button>
                    </div>
                </div>
            </div>

            {/* pagination */}
            <div className="flex justify-center mt-10 md:mt-20">
                <Stack spacing={2}>
                    <Pagination count={10} variant="outlined" shape="rounded" sx={paginationStyle} />
                </Stack>
            </div>

            <Footer />
        </div>
    );
}

export default Downloads;